@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';
@use '@airtel-web/legos/lib/styles/variables/gradients.module.scss';
@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';

.recommendations-card {
   @include common.get-flex(null, null, column);
   border-radius: 16px;
   width: 100%;
   background-color: colors.$TSurface-100;
   border: 1px solid colors.$TSurface-400;

   .recommendations-card-image {
      height: 180px;
      width: auto;
      border-radius: 15px 15px 0 0;
      object-fit: cover;
   }

   .recommendations-card-content {
      @include common.get-flex(center, null, column);
      flex: 1;
      padding: 8px 30px 25px;
      margin-right: 2px;

      .recommendations-card-title {
         padding: 13px 0 13px;
      }

      .recommendations-card-sub-title {
         padding: 0 0 5px;
      }

      .wt-info-tag {
         padding: 2px 4px;
      }
   }

   .recommendations-card-btn-black {
      margin-top: auto;
      padding-top: 35px;
      @include common.get-flex(null, center);
   }

   .recommendations-card-btn {
      margin-left: 14px;
   }

}