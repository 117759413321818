@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';
@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@import '../../../../common/scss/_colors.scss';

.recharge-section-container {
   background: colors.$TSurface-100;
   width: 100%;

   @include media.screen-below(sm) {
      padding: 28px 0 0;
   }

   @include media.screen-above(sm) {
      padding: 30px 0 40px;
   }

   .wt-input .wt-input--field-ap.append .wt-typography[data-type='body-single-line-sm'] {
      color: colors.$blue-500;
      font-size: 12px;
      font-family: TondoBold, sans-serif;
   }

   .recharge-heading {
      @include media.screen-below(sm) {
         align-self: flex-start !important;
         padding-left: 0 !important;
         margin-left: 28px;
      }
   }

   .badge-container {
      padding: 40px 0 18px 0;
      max-width: 100%;
      overflow-x: auto;
      margin: 0 auto;
      @include common.get-flex();

      @include media.screen-below(md) {
         padding: 20px 0 20px 0;
      }

      .badge-item-0 {
         margin-left: 27px;
      }

      button {
         margin-right: 12px;
      }

      @include media.screen-above(md) {
         justify-content: center;
      }

      scrollbar-width: none;
      /* Firefox */
      -ms-overflow-style: none;
      /* IE */
   }

   .badge-container::-webkit-scrollbar {
      /* WebKit */
      width: 0;
      height: 0;
   }

   .recharge-container {
      @include media.screen-above(sm) {
         width: 347px;
         align-self: center;
      }

      .recharge-input-container {
         @include media.screen-below(sm) {
            align-self: flex-start !important;
            margin: 0 28px;
         }
      }
   }

   .recharge-btn-container {
      width: 295px;
      margin-top: 28px;

      @include media.screen-above(md) {
         width: 347px;
      }
   }
}
