@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';
@use '@airtel-web/legos/lib/styles/variables/gradients.module.scss';
@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@import '../../../../common/scss/_colors.scss';

.offer-section-container {
   // background: linear-gradient(280.31deg, #E90000 7.69%, #FF4949 76.88%);
   background: linear-gradient(139deg, #fcedd8 28.54%, #f5c5c6 104.43%);
   height: 44px;

   @include common.get-flex(null, center);

   @include media.screen-below(md) {
      height: 56px;
   }

   .offer-section-row {
      width: 100%;

      @include media.screen-below(sm) {
         margin: 0 26px;
      }
   }

   .offer-item {
      @include common.get-flex(space-between, center);
      justify-content: center;

      .offer-item-left {
         justify-content: center;
         width: fit-content;
      }

      .offer-icon {
         margin-right: 4px;
      }

      .offer-text {
         color: colors.$black;
      }

      &-left {
         flex: 1;
         @include common.get-flex(null, center);
         gap: 4px;
      }

      .get-app-btn {
         margin-left: 8px;
         border: 1px solid colors.$black;
         background-color: transparent;
         box-sizing: border-box;
         color: colors.$black;
         border-radius: 14px;
         padding: 5px 8px !important;
         min-width: auto !important;

         @include media.screen-below(lg) {
            margin-right: -8px;
         }
      }
   }
}
