@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';

.hero-banner-slider-root {
   position: relative;
   width: 100%;
}

.hero-banner-slider-controls-root {
   position: absolute;
   bottom: 12px;
   left: 0;
   right: 0;
   z-index: 2;

   @include media.screen-below(md){
      bottom: 100px;
   }

}

.hero-banner-slider-controls {
   gap: 41px;

   @include common.get-flex(flex-start, center);

   @include media.screen-below(md) {
      gap: 20px;
   }
}