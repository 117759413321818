.sanchar-saathi {
   padding-top: 72px;
   padding-bottom: 80px;
   border-bottom: 1px solid #e8e8eb;
   @media screen and (max-width: 767px) {
      padding-top: 40px;
      padding-bottom: 24px;
   }

   img {
      width: 100%;
      border: 1px solid #e8e8eb;
      border-radius: 16px;
   }
}
