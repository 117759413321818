@use '@airtel-web/legos/lib/styles/mixins/common.scss';
@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';

.result-card-loader {
   @include common.get-flex(space-between, center, column);
   text-align: center;
   padding-bottom: 100px !important;
}

.result-card {
   width: 100%;
   position: relative;
   min-height: 386px;

   @include media.screen-below(md) {
      min-height: 432px;
   }

   &.low-padding {
      padding: 20px 20px 28px 20px;
   }

   @media screen and (min-width: 769px) {
      padding: 35px 24px !important;
   }

   .previousSlide {
      position: absolute;
      bottom: 14px;
      left: 16px;
   }

   .image {
      height: 95px;
      width: 95px;
   }

   .get-started-button-container {
      margin-top: 44px;
      margin-bottom: 24px;
      @include common.get-flex(center, center);

      .get-started-button {
         max-width: 272px;
      }
   }

   .heading,
   .subHeading,
   .label {
      text-align: center;
      margin-top: 15px;
   }

}