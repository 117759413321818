@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';

.skeleton-list {
   position: relative;
   display: flex;
   flex-direction: column;
   padding: 0;

   &.chips {
      flex-direction: row;
      justify-content: space-between;
      height: 100%;

      .skeleton-item {
         flex: 1;
         margin-right: 16px;
         min-width: 90px;
         height: 35px;
         border-radius: 50px;
      }
   }

   &.card {
      flex-direction: row;
      justify-content: space-between;
      height: 100%;
      min-height: 208px;

      .skeleton-item {
         flex: 1;
         margin-right: 16px;
         min-width: 90px;
         min-height: 208px;
         height: 100%;
         border-radius: 8px;
      }
   }

   &.chip {
      flex-direction: row;
      width: 100px;

      .skeleton-item {
         flex: 1;
         margin-right: 16px;
         height: 40px;
      }
   }

   &.icon {
      flex-direction: row;
      width: 36px;
      height: 36px;

      .skeleton-item {
         flex: 1;
         width: 36px;
         height: 36px;
         border-radius: 50%;
      }
   }

   &.list {
      .skeleton-item {
         height: 70px;
         border-radius: 8px;
      }
   }

   &.cardList2,
   &.cardList5 {
      flex-direction: row;
      gap: 16px;

      .skeleton-item {
         flex: 1;
         height: 120px;
      }
   }

   &.heading {
      .skeleton-item {
         height: 40px;
         width: 80%;
         min-width: 350px;
      }
   }

   &.badge {
      .skeleton-item {
         height: 20px;
         width: 50px;
      }
   }

   &.subHeading {
      .skeleton-item {
         height: 20px;
         width: 120px;
         min-width: 50%;
      }
   }

   &.chatProfile {
      &::before {
         content: '';
         position: absolute;
         left: 0;
         top: 0;
         height: 42px;
         width: 42px;
         border-radius: 50px;
         background-color: #eeeeee;
      }

      .skeleton-item {
         height: 14px;
         margin-left: 52px;
         margin-bottom: 0;

         &:first-child {
            height: 20px;
            margin-bottom: 10px;
         }
      }
   }

   .skeleton-item {
      position: relative;
      height: 70px;
      background: linear-gradient(279.08deg,
            colors.$lilac-200 107.2%);
      background-size: 400% 400%;
      background-position: 30% 0%;
      border-radius: 4px;
      // animation: gradient 1.3s infinite;
      box-shadow: 0 4px 20px 0 rgba(106, 112, 121, 0.08);

      &:last-child {
         margin-right: 0;
      }
   }

   @keyframes gradient {
      100% {
         background-position: -40% 0%;
      }
   }
}