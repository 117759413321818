@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';
@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';

.switch-to-airtel-links-container {
    padding-bottom: 30px;

    @include common.get-flex(center, center, row);
    gap: 32px;
    flex-wrap: wrap;

    @include media.screen-above(lg) {
        gap: 64px;

        .wt-typography {
            font-size: 14px;
            line-height: 20px;
        }
    }

    .switch-to-airtel-link {
        width: 80px;
    }

    .wt-ib-c {
        .wt-btn {
            background-color: colors.$lilac-400;
        }
    }
}