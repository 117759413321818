@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@import './common/scss/utilities';

.icon-button-black800 {
   .wt-ib-c .wt-btn:disabled {
      background: transparent;
      border: 1px solid colors.$lilac-900;
      padding: 0;

      & path {
         stroke: colors.$lilac-600;
      }
   }
}
