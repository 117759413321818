@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';

.home-banner-skeleton {
   @include common.get-flex();

   @include media.screen-below(md) {
      @include common.get-flex(null, null, column);
   }

   .home-banner-content-skeleton {
      @include common.get-flex(null, null, column);
      flex: 1;
      padding: 30px 50px;
      gap: 16px;

      @include media.screen-below(md) {
         order: 2;
         @include common.get-flex(center, center, column);
      }

      @include media.screen-above(md) {
         padding: 80px;
      }
   }

   .home-banner-buttons-skeleton {
      @include common.get-flex(null, center);
      gap: 16px;
      margin-top: 32px;

      @include media.screen-below(md) {
         @include common.get-flex(center, center, column);
      }
   }

   .home-banner-actions-skeleton {
      @include common.get-flex(null, center);
      gap: 16px;
      margin-top: auto;
   }

   .home-banner-image-skeleton {
      flex: 1;
      padding: 16px;
      min-height: 204px;
   }
}
