@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';

.conversation-card {
   width: 100%;
   position: relative;
   min-height: 412px;

   @include media.screen-below(md) {
      min-height: 432px;
   }

   &.low-padding {
      padding: 20px 20px 28px 20px;
   }

   &.conversation-card-hidden {
      display: none;
   }

   .conversation-card-inner {
      gap: 24px;
   }

   @media screen and (min-width: 769px) {
      padding: 20px 20px 28px 20px;
   }

   @include media.screen-below(sm) {
      padding: 20px 12px 12px 12px;
   }

   .button-container {
      margin-top: 15px !important;
      gap: 16px;

      @include media.screen-below(sm) {
         margin: 0 auto;
      }
   }

   .conversational-card-controls {
      @include common.get-flex(space-between, center);
   }

   .gradient-div {
      position: absolute;
      top: -1px;
      left: -1px;
      right: -1px;
      bottom: -1px;
      background: linear-gradient(90deg,
            rgba(255, 255, 255, 0.5) 0%,
            rgba(155, 169, 197, 0.84) 47.92%,
            #5e75a2 100%);
      border-radius: 16px;
   }

   .conversational-widget-badge {
      @include common.get-flex(center, center);

      .wt-badge {
         width: 100% !important;
         max-width: 188px;
      }

      .wt-typography {
         cursor: pointer;
      }

      .wt-badge[data-conversation-type=usage-true] {
         justify-content: space-between;

         .wt-btn-text {
            flex: 1;
            @include common.get-flex(center);
         }
      }
   }


   .heading,
   .subHeading,
   .label {
      text-align: center;
   }

   .label {
      text-transform: uppercase;
   }
}