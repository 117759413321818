@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';

.fallback-page-container {
    padding: 30px 0 30px;
    background-color: colors.$black-100;

    .heading {
        text-align: center;
    }

    .image {
        max-width: 100%;
        height: auto;
    }

    .subheading {
        margin-top: 20px;
        text-align: center;
    }

    .subheading-2 {
        margin: 16px 0 16px;
        text-align: center;
    }

    .link-card {
        background-color: colors.$white;
        padding: 8px 2px;
        border-radius: 4px;
        margin: 4px;
        cursor: pointer;
        height: 92px;
        width: 101px;
        box-shadow: 0 1px 5px 0 rgb(0 0 0 / 16%);

        @include common.get-flex(space-between, center, column);

        @include media.screen-above(md) {
            height: 130px;
            width: 130px;
            padding: 20px;
            margin: 0;
        }
    }

    .links-blocks {
        @include common.get-flex(center, center);

        @include media.screen-below(sm) {
            flex-basis: 50%;
            max-width: 50%;
        }
    }


    .align-center {
        text-align: center;
    }
}