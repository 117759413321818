@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';

.recommendations-section-container {
   position: relative;
   width: 100%;
   background-color: colors.$TSurface-200;
   padding-top: 40px;
   padding-bottom: 24px;

   @include media.screen-above(md) {
      padding-top: 72px;
      padding-bottom: 32px;
   }

   .wt-slider-main {
      cursor: auto;
   }

   .recommendations-section-heading {
      margin-bottom: 20px;

      @include media.screen-below(sm) {
         align-self: flex-start !important;
         padding-left: 0 !important;
         margin-left: 28px;
      }
   }

   .controls-column {
      margin-top: 24px;
   }

   .slider-control-btn-section {
      @include common.get-flex(center, center);
      gap: 36px;

      @include media.screen-above(md) {
         @include common.get-flex(center, center);
      }
   }
}
