@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';
@import '../../../../../../common/scss/_colors.scss';

.landing-loading-container {
   overflow-y: hidden;
   height: 100vh;

   .home-header-container-skeleton {
      @include common.get-flex(space-between, center);
      background-color: colors.$black;
      height: 100px;
      padding: 0 32px;

      &>div {
         @include common.get-flex(null, center);
         gap: 16px;
      }
   }

   .announcement-bar-skeleton {
      min-height: 36px;
      background-color: $announcement-bar-success;
   }

   .home-recharges-skeleton {
      @include common.get-flex(center, center, column);
      padding: 70px;
      gap: 32px;
   }

   .wt-header-m-me-c {
      z-index: 1;
   }
}