@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/fonts';

* {
   box-sizing: border-box;
   outline: none;
   -webkit-font-smoothing: auto;
   -moz-osx-font-smoothing: auto;
   -webkit-tap-highlight-color: transparent;
}

html,
body,
#root {
   position: relative;
   width: 100%;
   display: flex;
   flex-direction: column;
   color: colors.$black-700;
   margin: 0;
   padding: 0;
}

hr {
   margin: 0;
}

strong,b {
   @include fonts.get-font-family('primary-bold-font');
} 
