@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';
@use '@airtel-web/legos/lib/styles/mixins/fonts';

.cookies-container {
    &.wt-toast-cntnr.wt-toast-position {
        bottom: 40px;
    }

    &.wt-toast-cntnr {
        padding: 25px 28px 25px 20px;
        height: 68px;
        gap: 12px;

        @include media.screen-below(md) {
            width: calc(100% - 56px) !important;
            padding: 16px 28px 16px 20px;
        }

        .wt-toast-text {
            color: colors.$black-300  !important;
            @include fonts.get-font-family('primary-font');
        }
    }

}