@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';
@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@use '@airtel-web/legos/lib/styles/variables/gradients.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';

.subscriptions-card {
   @include common.get-flex(null, null, column);
   border-radius: 16px;
   width: 100%;
   border: 1px solid colors.$TSurface-400;
   background-color: colors.$TSurface-100;

   .subscription-card-image {
      height: 90px;
      width: 100%;
      border-radius: 16px 16px 0 0;
   }

   .subscriptions-card-content {
      @include common.get-flex(center, null, column);
      flex: 1;
      padding: 8px 16px 18px;

      .subscription-card-main-title {
         margin-bottom: 4px;
      }

      .wt-info-tag {
         padding: 2px 4px;
      }
   }

   .subscriptions-card-content-price {
      margin-top: 8px;
      @include common.get-flex(null, center);
   }

   .subscriptions-card-content-cutprice-container {
      margin-left: 4px;
      margin-right: 8px;

      @include media.screen-below(sm) {
         margin-left: 2px;
         margin-right: 2px;
      }
   }

   .subscriptions-card-content-cutprice {
      text-decoration: line-through 0.5px;
   }

   .subscriptions-card-content-discount {
      // background: linear-gradient(280.31deg, #E90000 7.69%, #FF4949 76.88%) !important;
   }
}
