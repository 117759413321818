@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@use '@airtel-web/legos/lib/styles/variables/gradients.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';


.hero-banner-slide-root {
   width: 100%;
   min-height: 386px;
   max-height: 500px;
   height: calc(386px + ((100vw - 360px) * 3 / 4));
}

.hero-banner-bg-image {
   height: 100%;
   background-repeat: no-repeat;
   background-position: bottom;
   background-size: 100%;
}

@include media.screen-above(sm) {
   .hero-banner-slide-root {
      height: 500px;
   }

   .hero-banner-bg-image {
      background-position: 0 calc(100% + 60px);
   }
}

@include media.screen-above(md) {
   .hero-banner-slide-root {
      height: 400px;
   }

   .hero-banner-bg-image {
      background-size: 680px 100%;
      background-position: calc(100% + 130px);
   }
}

@include media.screen-above(lg) {
   .hero-banner-bg-image {
      background-position: right;
   }
}

.hero-banner-container {
   padding-top: 76px;

   @include media.screen-below(md) {
      padding-top: 25px;
      gap: 8px;
   }
}

.hero-banner-content-container {
   @include media.screen-below(md) {
      order: 2;
   }
}

.hero-banner-label {
   @include common.get-flex(center, center);
}

.hero-banner-heading {
   margin-bottom: 12px;
}

.hero-banner-cta-container {
   gap: 16px;
   padding-top: 20px;

   @include common.get-flex(null, center);

   @include media.screen-below(md) {
      @include common.get-flex(null, flex-start, column);
   }

}