@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';
@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@import '../../../../common/scss/_colors.scss';

.b2b-section-container {
   position: relative;
   width: 100%;
   background-color: colors.$TSurface-200;
   padding-top: 40px;
   padding-bottom: 24px;

   @include media.screen-above(md) {
      padding-top: 72px;
      padding-bottom: 80px;
   }

   .b2b-main-title-container {
      margin: 0 15px 16px;

      @include media.screen-below(md) {
         margin: 0 28px 12px;
      }
   }

   .b2b-sub-title-container {
      margin: 0 15px 8px;
      
      @include media.screen-below(md) {
         margin: 0 28px 8px;
      }
   }

   .b2b-cta {
      margin: 0 15px 12px;

      @include media.screen-below(md) {
         margin: 0 28px 0;
      }
   }

   .wt-slider-main {
      margin: 15px 0 5px;
   }

   .controls-column {
      margin-top: 24px;
   }

   .slider-control-btn-section {
      @include common.get-flex(center, center);
      gap: 36px;

   }
}