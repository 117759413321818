@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';
@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';

.b2b-slide-container {
   width: 100%;
   min-height: 144px;
   border: 1px solid colors.$TSurface-400;
   border-radius: 16px;
   background: colors.$TSurface-100;
   padding: 16px;
   cursor: pointer;

   @include media.screen-above(md) {
      max-width: 252px;
   }

   @include media.screen-below(sm) {
      padding: 12px;
   }

   .icon-container {
      margin-bottom: 8px;

      .b2b-icon {
         width: 44px;
         height: 44px;
      }
   }

   .b2b-slide-content {

      .slide-heading-container {
         @include common.get-flex(null, center);
         gap: 8px;
         gap: 4px;
         margin-bottom: 4px;
      }
   }
}