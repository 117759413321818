@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/common.scss';

.subscriptions-root {
   background-color: colors.$TSurface-100;
   padding: 48px 0 28px;
   width: 100%;

   @include common.get-flex(null, null, column);

   @include media.screen-above(md) {
      padding: 72px 0 40px;
   }

   .subscriptions-heading {

      @include media.screen-below(sm) {
         align-self: flex-start !important;
         padding-left: 0 !important; 
         margin-left: 28px;
      }
   }

   .subscription-root-grid {
      gap: 20px;

      @include media.screen-above(md) {
         gap: 56px;
      }
   }

   .controls-column-subscription {
      margin-top: 24px;
   }

   .subscriptions-slider-controls {
      @include common.get-flex(center, center);
      gap: 36px;
   }
}