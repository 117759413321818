@use '@airtel-web/legos/lib/styles/variables/colors.module.scss';
@use '@airtel-web/legos/lib/styles/mixins/media.module.scss';

.conversational-widget-root {
   background-image: url('https://assets.airtel.in/static-assets/home-recast/images/conversational-widget-bg-mob.png');
   background-position: bottom;
   background-repeat: no-repeat;
   background-size: cover;
   padding: 48px 0 28px;

   @include media.screen-above(md) {
      background-image: url('https://assets.airtel.in/static-assets/home-recast/images/conversational-widget-bg-web.png');
      padding: 72px 0 40px;
   }

   .conversational-widget-heading-container{
      margin-bottom: 20px;
      @include media.screen-below(sm) {
         align-self: flex-start !important;
      }

      @include media.screen-above(md) {
         margin-bottom: 16px;
      }
   }

   .wt-slider-container .wt-slider-indicator-container {
      bottom: 16px;
      gap: 8px;
      width: unset;

      .wt-slider-indicator-custom {
         position: relative;
      }

      .wt-slider-indicator-custom:first-child {
         display: none;
      }

      .wt-slider-indicator-custom:not(:last-child)::after {
         content: ' ';
         display: block;
         position: absolute;
         height: 1px;
         background: colors.$black-600;
         width: 8px;
         left: 8px;
         top: calc(50% - 0.5px);
      }
   }
}
